@use 'design-system/shadows';
@use 'design-system/colors';
@use 'design-system/fonts';
@use 'design-system/radii';
@use 'sass:color';

@import '/node_modules/@ngneat/hot-toast/src/styles/styles';

.hot-toast-bar-base {
  display: inline-flex;
  align-items: center;

  border-radius: radii.$small !important;
  background: colors.$white;
  color: colors.$gray-900;

  padding: 1rem !important;
  min-width: 300px;

  &.info,
  &.action {
    --color: #{colors.$purple};
    --bg: #{color.adjust(colors.$electricGreen, $lightness: 34%)};
  }

  &.error {
    --color: #{colors.$red};
    --bg: #{color.adjust(colors.$red, $lightness: 32%)};
  }

  &.warning {
    --color: #{colors.$orange};
    --bg: #{color.adjust(colors.$orange, $lightness: 30%)};
  }

  &.success {
    --color: #{colors.$green};
    --bg: #{color.adjust(colors.$green, $lightness: 46%)};
  }

  &::before {
    content: '';
    display: block;

    position: absolute;
    left: 0.8rem;

    height: calc(100% - 1.5rem);
    width: 4px;

    border-radius: 999px;
    background: var(--color, colors.$gray-200);
  }

  .hot-toast-icon {
    padding-top: unset !important;
  }

  .hot-toast-info-icon {
    background-color: colors.$purple !important;
  }

  .hot-toast-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--bg, colors.$gray-100);
    padding: 0.5rem;

    margin-left: 1rem;
    margin-right: 0.5rem;
    border-radius: radii.$full;
  }

  .hot-toast-message {
    justify-content: flex-start !important;

    > div {
      max-width: 50ch;
      color: rgba(colors.$gray-900, 0.8);

      font-size: fonts.$size-xs;
      font-weight: fonts.$weight-semi-bold;
      line-height: fonts.$line-height-base;
      letter-spacing: 0.0125em;
      word-wrap: break-word;

      justify-content: flex-start;

      > dynamic-view > * > {
        * {
          margin: 0;

          &:empty {
            display: none;
          }
        }

        b {
          color: var(--color, colors.$gray-900) !important;
          line-height: fonts.$line-height-base;
          text-transform: capitalize;
        }
      }
    }
  }
}
