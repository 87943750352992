@use 'foundation-sites/scss/util/util' as foundation;
@use '../../styles/design-system/shadows';
@use '../../styles/design-system/colors';
@use '../../styles/design-system/fonts';
@use '../../styles/design-system/modal';
@use '../../styles/design-system/radii';

// DESIGN-SYSTEM-TODO: Replace.
@import '../../styles/design-system/variables';

/* stylelint-disable selector-max-class */

:host {
  overflow: hidden;
}

.modal-wrapper {
  height: 100%;
  width: 100%;
}

.search-padding .modal-window .modal-center-fix .row .modal-content {
  padding: 1rem 3rem !important;
}

.modal {
  height: calc(100vh - env(safe-area-inset-bottom, 0)) !important;

  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  overflow: auto;
  position: fixed;
  transition: opacity $defaultAnimation;
  width: 100vw;

  &.modal-visible {
    opacity: 1;
    top: 0;

    .modal-window {
      box-shadow: shadows.$small;
    }
  }

  @extend %z-index;

  &.low-z-index {
    isolation: isolate;
    z-index: 99;
  }

  // Assures going over Userflow.
  &.high-z-index {
    isolation: isolate;
    z-index: 9999999999;
  }

  &.padded {
    margin: 1rem;
    overflow: hidden;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    border-radius: radii.$small;
  }
}

.modal-window {
  background-color: colors.$white;
  border-radius: radii.$regular;
  box-shadow: none;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
  overflow: hidden;
  transition: box-shadow $slowAnimation;

  &.with-padding {
    padding: 1.25rem 0.5rem;
  }

  &.black::after {
    content: '';
    background-color: rgba(colors.$black, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &,
  p,
  .btn-link {
    font-size: fonts.$size-sm;
  }

  @include foundation.breakpoint(small only) {
    margin: auto;
    max-width: 80vw;
    width: 80vw;
  }

  .modal-center-fix {
    min-width: 200px;

    .row {
      max-width: $__design2RowWidth;
    }
  }

  &.modal-hidden {
    opacity: 0;
  }

  img {
    max-height: calc(100vh - 100px);
    object-fit: contain;
    max-width: 100%;
    width: 100%;
  }

  // Exceptions
  &.no-min-height {
    min-height: unset !important;
  }

  // Modal sizes
  // Error/confirm small
  &.small {
    max-width: 400px;
  }

  // Default error/confirm
  &.medium {
    max-width: 500px;
  }

  // Larger error/confirm message because of more text or extra buttons
  &.large {
    max-width: 600px;
  }

  // Modals for invoice input, showing lists, etc. and datepickers
  &.xlarge {
    max-width: 1440px;
    min-height: 50vh;
    width: 80vw;
  }

  &.xxlarge {
    max-width: 1440px;
    min-height: 70vh;
    width: 80vw;
    padding: 0;
    position: relative;
    height: fit-content;

    @media screen and (max-width: 500px) {
      min-height: 40vh;
    }
  }

  &.full-screen {
    width: 100% !important;
    max-width: 100%;
    min-height: 100%;
    border-radius: 0;
    height: fit-content;
    padding: 0;

    &.padded {
      height: inherit !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  }

  // Modals for showing pdf's can almost take up the whole screen
  &.pdf {
    display: table;
    max-height: 90vh;
    overflow: auto;
    top: 5vh;
  }

  .modal-header {
    margin-bottom: 1rem;
  }
}

// General modal layouts
modal-header {
  font-weight: bolder;
  font-size: fonts.$size-xl;
  margin-bottom: 2em;
}

.modal-message,
.modal-banner {
  --color: #{colors.$gray-600};
  --bg-color: #{colors.$gray-400};
  --max-width: 1440px;
  --width: 80vw;

  > modal-message,
  > modal-banner {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr min-content;
    max-width: var(--max-width);
    width: var(--width);

    padding: 1rem;
    margin: 0.5rem auto;

    color: var(--color);
    background: var(--bg-color);
    border-radius: radii.$small;

    > * {
      // Get rid of odd stylings.
      margin: unset;
    }
  }

  &.small {
    --max-width: 400px;
  }

  &.medium {
    --max-width: 500px;
  }

  &.large {
    --max-width: 600px;
  }

  &.xlarge {
    --max-width: 1440px;
    --width: 80vw;
  }

  &.xxlarge {
    --max-width: 1440px;
    --width: 80vw;
  }

  &:empty {
    display: none;
    height: 0;
  }
}

.modal-content {
  position: relative;
}

.modal-buttons {
  text-align: right;

  a,
  .btn-link {
    transition: all $defaultAnimation;
  }

  .btn-link {
    padding: 0.6rem 1rem;
    text-align: center;
    display: inline-block;
    font-weight: bolder;
    border-radius: radii.$regular;

    &.gray,
    &[gray],
    &[disabled],
    &.disabled {
      color: colors.$gray-600;

      &:hover {
        background-color: rgba(colors.$gray-600, 0.1);
      }
    }

    &.purple,
    &[purple] {
      color: colors.$purple;

      &:hover {
        background-color: colors.$green-10;
      }
    }

    &.green,
    &[green] {
      color: colors.$green;

      &:hover {
        background-color: rgba(colors.$green, 0.1);
      }
    }

    &.yellow,
    &[yellow] {
      color: colors.$yellow;

      &:hover {
        background-color: rgba(colors.$yellow, 0.1);
      }
    }

    &.red,
    &[red] {
      color: colors.$red;

      &:hover {
        background-color: rgba(colors.$red, 0.1);
      }
    }

    &[disabled],
    &.disabled {
      cursor: not-allowed;

      &:hover {
        background-color: colors.$white;
      }
    }
  }

  ::ng-deep {
    fnc-button {
      margin: 0 1rem 0 0;
      min-width: 0;
      padding: 0;

      // set color
      &[green],
      .green {
        .mdl-button {
          color: colors.$green;
        }
      }

      &[blue],
      .blue {
        .mdl-button {
          color: colors.$blue;
        }
      }

      &[red],
      .red {
        .mdl-button {
          color: colors.$red;
        }
      }

      &[orange],
      .orange {
        .mdl-button {
          color: colors.$orange;
        }
      }

      &[purple],
      .purple {
        .mdl-button {
          color: colors.$purple;
        }
      }

      &[yellow],
      .yellow {
        .mdl-button {
          color: colors.$yellow;
        }
      }

      button.mdl-button {
        background-color: transparent !important;
        font-weight: fonts.$weight-regular;
        font-size: fonts.$size-base;
        margin: 0;
        min-width: 0;
        padding: 0;

        &[disabled],
        .disabled {
          color: colors.$gray-600 !important;
        }
      }

      .mdl-button:hover {
        color: colors.$purple;
      }
    }
  }

  &.no-border {
    border-top: 0;
  }
}

.modal-overlay {
  @extend %backdrop;

  isolation: isolate;
  z-index: 99;
  opacity: 0;

  &.modal-visible {
    opacity: 1;
  }

  &.no-overlay {
    display: none;
  }
}

// CSS classes for triple support modal
.no-background {
  background-color: transparent !important;
  box-shadow: none !important;
}

.max-50-vw {
  @include foundation.breakpoint(xxlarge up) {
    width: 50vw !important;
  }
}
