@use 'foundation-sites/scss/util/util' as foundation;
@use './design-system/colors';
@use './design-system/fonts';

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: colors.$blue;
  font-family: $font-family;
}

h1,
h2 {
  margin: 0 0 40px 0;
}

h1 {
  font-weight: bold;
  font-size: $__design2H1;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2H1Large;
    line-height: $__design2H1Large + 15px;
  }

  line-height: $__design2H1 + 15px;
}

h2 {
  font-weight: bold;
  font-size: $__design2H2;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2H2Large;
  }

  line-height: 36px;

  &.lighter,
  &[lighter] {
    font-weight: fonts.$weight-regular;
  }
}

label {
  font-size: $__design2DefaultFontSize;
}

fnc-input-container {
  input {
    ::ng-deep {
      font-size: $__design2InputSize;
    }
  }
}

li {
  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }
}

input {
  font-size: $__design2InputSize;
}

p,
a {
  font-weight: fonts.$weight-semi-bold;
}

p {
  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }

  line-height: 20px;
  margin: 0 0 20px 0;
}

p.small,
p[small] {
  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }

  line-height: 16px;
}

p.tiny,
p[tiny] {
  font-size: $__design2SmallFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P2Large;
  }

  line-height: 14px;
}

p.intro,
p[intro] {
  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }

  line-height: 22px;
}

p.bold {
  font-weight: bold;
}

a {
  color: colors.$blue;

  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }

  &:hover {
    color: colors.$blue;
    opacity: 0.8;
  }
}

span {
  font-size: $__design2DefaultFontSize;

  @include foundation.breakpoint(xxlarge) {
    font-size: $__design2P1Large;
  }
}

fnc-select-value-inner {
  font-size: $__design2DefaultFontSize;
}
