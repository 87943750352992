$lightGrey: #eeeeee;
$darkNavy: #011532;
$white: #ffffff;

$blue: #58c0fc;
$green: #4bbc90;
$yellow: #f6bf3b;
$orange: #ffb366;
$red: #ff5f59;
$purple: #7c87bf;
$lightGreen: #50e3c2;
$brown: #8b572a;
$grey: #9b9b9b;

// Template colors
$template-aqua: #38cddd;
$template-blue: #30a2f2;
$template-darkNavy: #384ba6;
$template-purple: #9730b1;
$template-red: #e9286f;
$template-orange: #ef5f5e;
$template-turquoise: #34afa4;
$template-green: #5ab65e;
$template-lightGreen: #b8e986;
$template-yellow: #f8e71c;
$template-white: #f3f3f3;
$template-black: #000000;
