/* stylelint-disable font-family-name-quotes, font-family-no-missing-generic-family-keyword */

@mixin fnc-icon() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'fynciconfont';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}
@mixin icon-contacts-a() {
  content: '\e901';
}
@mixin icon-contacts-b() {
  content: '\e900';
}
@mixin icon-library-a() {
  content: '\e903';
}
@mixin icon-library-b() {
  content: '\e902';
}
@mixin icon-alert-a() {
  content: '\e905';
}
@mixin icon-alert-b() {
  content: '\e904';
}
@mixin icon-vat-a() {
  content: '\e907';
}
@mixin icon-vat-b() {
  content: '\e906';
}
@mixin icon-calendar-a() {
  content: '\e909';
}
@mixin icon-calendar-b() {
  content: '\e908';
}
@mixin icon-camera-a() {
  content: '\e90b';
}
@mixin icon-camera-b() {
  content: '\e90a';
}
@mixin icon-checklist-a() {
  content: '\e90d';
}
@mixin icon-checklist-b() {
  content: '\e90c';
}
@mixin icon-support-a() {
  content: '\e90f';
}
@mixin icon-support-b() {
  content: '\e90e';
}
@mixin icon-download-a() {
  content: '\e911';
}
@mixin icon-download-b() {
  content: '\e910';
}
@mixin icon-new-invoice-a() {
  content: '\e913';
}
@mixin icon-new-invoice-b() {
  content: '\e912';
}
@mixin icon-email-a() {
  content: '\e915';
}
@mixin icon-email-b() {
  content: '\e914';
}
@mixin icon-invoice-a() {
  content: '\e917';
}
@mixin icon-invoice-b() {
  content: '\e916';
}
@mixin icon-filter-a() {
  content: '\e919';
}
@mixin icon-filter-b() {
  content: '\e918';
}
@mixin icon-flash-a() {
  content: '\e91b';
}
@mixin icon-flash-b() {
  content: '\e91a';
}
@mixin icon-picture-a() {
  content: '\e91d';
}
@mixin icon-picture-b() {
  content: '\e91c';
}
@mixin icon-remind-a() {
  content: '\e91f';
}
@mixin icon-remind-b() {
  content: '\e91e';
}
@mixin icon-home-a() {
  content: '\e921';
}
@mixin icon-home-b() {
  content: '\e920';
}
@mixin icon-settings-a() {
  content: '\e923';
}
@mixin icon-settings-b() {
  content: '\e922';
}
@mixin icon-clock-a() {
  content: '\e925';
}
@mixin icon-clock-b() {
  content: '\e924';
}
@mixin icon-exit-a() {
  content: '\e927';
}
@mixin icon-exit-b() {
  content: '\e926';
}
@mixin icon-close-a() {
  content: '\e929';
}
@mixin icon-close-b() {
  content: '\e928';
}
@mixin icon-empty-invoice-a() {
  content: '\e92b';
}
@mixin icon-empty-invoice-b() {
  content: '\e92a';
}
@mixin icon-menu-a() {
  content: '\e92d';
}
@mixin icon-menu-b() {
  content: '\e92c';
}
@mixin icon-attachment-a() {
  content: '\e92f';
}
@mixin icon-attachment-b() {
  content: '\e92e';
}
@mixin icon-arrow-right-a() {
  content: '\e931';
}
@mixin icon-arrow-right-b() {
  content: '\e930';
}
@mixin icon-arrow-a() {
  content: '\e933';
}
@mixin icon-arrow-b() {
  content: '\e932';
}
@mixin icon-plus-a() {
  content: '\e935';
}
@mixin icon-plus-b() {
  content: '\e934';
}
@mixin icon-plus2-a() {
  content: '\e937';
}
@mixin icon-plus2-b() {
  content: '\e936';
}
@mixin icon-user-a() {
  content: '\e939';
}
@mixin icon-user-b() {
  content: '\e938';
}
@mixin icon-lock-a() {
  content: '\e93b';
}
@mixin icon-lock-b() {
  content: '\e93a';
}
@mixin icon-phone-a() {
  content: '\e93d';
}
@mixin icon-phone-b() {
  content: '\e93c';
}
@mixin icon-overview-a() {
  content: '\e93f';
}
@mixin icon-overview-b() {
  content: '\e93e';
}
@mixin icon-expenditure-a() {
  content: '\e941';
}
@mixin icon-expenditure-b() {
  content: '\e940';
}
@mixin icon-upload-a() {
  content: '\e943';
}
@mixin icon-upload-b() {
  content: '\e942';
}
@mixin icon-send-a() {
  content: '\e945';
}
@mixin icon-send-b() {
  content: '\e944';
}
@mixin icon-confirm-a() {
  content: '\e947';
}
@mixin icon-confirm-b() {
  content: '\e946';
}
@mixin icon-search-a() {
  content: '\e949';
}
@mixin icon-search-b() {
  content: '\e948';
}
@mixin icon-zoom-out-a() {
  content: '\e950';
}
@mixin icon-zoom-out-b() {
  content: '\e94a';
}
@mixin icon-zoom-in-a() {
  content: '\e951';
}
@mixin icon-zoom-in-b() {
  content: '\e94b';
}
@mixin icon-payment-difference-a() {
  content: '\e952';
}
@mixin icon-payment-difference-b() {
  content: '\e94c';
}
@mixin icon-replace-a() {
  content: '\e94f';
}
@mixin icon-replace-b() {
  content: '\e94e';
}
@mixin icon-business-a() {
  content: '\e953';
}
@mixin icon-business-b() {
  content: '\e94d';
}
@mixin icon-no-file-a() {
  content: '\e955';
}
@mixin icon-no-file-b() {
  content: '\e954';
}
@mixin register-icons() {
  @font-face {
    font-display: swap;
    font-family: 'fynciconfont';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/iconfont/fynciconfont.eot'), url('../assets/iconfont/fynciconfont.eot?#iefix') format('embedded-opentype'),
      url('../assets/iconfont/fynciconfont.woff') format('woff'), url('../assets/iconfont/fynciconfont.ttf') format('truetype'),
      url('../assets/iconfont/fynciconfont.svg#fynciconfont') format('svg');
  }

  [class*='icon-']::before {
    @include fnc-icon();
  }

  .icon-alert-a::before {
    @include icon-alert-a();
  }

  .icon-alert-b::before {
    @include icon-alert-b();
  }

  .icon-arrow-a::before {
    @include icon-arrow-a();
  }

  .icon-arrow-b::before {
    @include icon-arrow-b();
  }

  .icon-attachment-a::before {
    @include icon-attachment-a();
  }

  .icon-attachment-b::before {
    @include icon-attachment-b();
  }

  .icon-calendar-a::before {
    @include icon-calendar-a();
  }

  .icon-calendar-b::before {
    @include icon-calendar-b();
  }

  .icon-camera-a::before {
    @include icon-camera-a();
  }

  .icon-camera-b::before {
    @include icon-camera-b();
  }

  .icon-checklist-a::before {
    @include icon-checklist-a();
  }

  .icon-checklist-b::before {
    @include icon-checklist-b();
  }

  .icon-clock-a::before {
    @include icon-clock-a();
  }

  .icon-clock-b::before {
    @include icon-clock-b();
  }

  .icon-close-a::before {
    @include icon-close-a();
  }

  .icon-close-b::before {
    @include icon-close-b();
  }

  .icon-confirm-a::before {
    @include icon-confirm-a();
  }

  .icon-confirm-b::before {
    @include icon-confirm-b();
  }

  .icon-contacts-a::before {
    @include icon-contacts-a();
  }

  .icon-contacts-b::before {
    @include icon-contacts-b();
  }

  .icon-download-a::before {
    @include icon-download-a();
  }

  .icon-download-b::before {
    @include icon-download-b();
  }

  .icon-email-a::before {
    @include icon-email-a();
  }

  .icon-email-b::before {
    @include icon-email-b();
  }

  .icon-empty-invoice-a::before {
    @include icon-empty-invoice-a();
  }

  .icon-empty-invoice-b::before {
    @include icon-empty-invoice-b();
  }

  .icon-exit-a::before {
    @include icon-exit-a();
  }

  .icon-exit-b::before {
    @include icon-exit-b();
  }

  .icon-expenditure-a::before {
    @include icon-expenditure-a();
  }

  .icon-expenditure-b::before {
    @include icon-expenditure-b();
  }

  .icon-filter-a::before {
    @include icon-filter-a();
  }

  .icon-filter-b::before {
    @include icon-filter-b();
  }

  .icon-flash-a::before {
    @include icon-flash-a();
  }

  .icon-flash-b::before {
    @include icon-flash-b();
  }

  .icon-home-a::before {
    @include icon-home-a();
  }

  .icon-home-b::before {
    @include icon-home-b();
  }

  .icon-invoice-a::before {
    @include icon-invoice-a();
  }

  .icon-invoice-b::before {
    @include icon-invoice-b();
  }

  .icon-library-a::before {
    @include icon-library-a();
  }

  .icon-library-b::before {
    @include icon-library-b();
  }

  .icon-menu-a::before {
    @include icon-menu-a();
  }

  .icon-menu-b::before {
    @include icon-menu-b();
  }

  .icon-new-invoice-a::before {
    @include icon-new-invoice-a();
  }

  .icon-new-invoice-b::before {
    @include icon-new-invoice-b();
  }

  .icon-overview-a::before {
    @include icon-overview-a();
  }

  .icon-overview-b::before {
    @include icon-overview-b();
  }

  .icon-phone-a::before {
    @include icon-phone-a();
  }

  .icon-phone-b::before {
    @include icon-phone-b();
  }

  .icon-picture-a::before {
    @include icon-picture-a();
  }

  .icon-picture-b::before {
    @include icon-picture-b();
  }

  .icon-plus-a::before {
    @include icon-plus-a();
  }

  .icon-plus-b::before {
    @include icon-plus-b();
  }

  .icon-plus2-a::before {
    @include icon-plus2-a();
  }

  .icon-plus2-b::before {
    @include icon-plus2-b();
  }

  .icon-remind-a::before {
    @include icon-remind-a();
  }

  .icon-remind-b::before {
    @include icon-remind-b();
  }

  .icon-search-a::before {
    @include icon-search-a();
  }

  .icon-search-b::before {
    @include icon-search-b();
  }

  .icon-send-a::before {
    @include icon-send-a();
  }

  .icon-send-b::before {
    @include icon-send-b();
  }

  .icon-settings-a::before {
    @include icon-settings-a();
  }

  .icon-settings-b::before {
    @include icon-settings-b();
  }

  .icon-support-a::before {
    @include icon-support-a();
  }

  .icon-support-b::before {
    @include icon-support-b();
  }

  .icon-upload-a::before {
    @include icon-upload-a();
  }

  .icon-upload-b::before {
    @include icon-upload-b();
  }

  .icon-user-a::before {
    @include icon-user-a();
  }

  .icon-user-b::before {
    @include icon-user-b();
  }

  .icon-vat-a::before {
    @include icon-vat-a();
  }

  .icon-vat-b::before {
    @include icon-vat-b();
  }

  .icon-arrow-right-a::before {
    @include icon-arrow-right-a();
  }

  .icon-arrow-right-b::before {
    @include icon-arrow-right-b();
  }

  .icon-lock-a::before {
    @include icon-lock-a();
  }

  .icon-lock-b::before {
    @include icon-lock-b();
  }

  .icon-zoom-out-a::before {
    @include icon-zoom-out-a();
  }

  .icon-zoom-out-b::before {
    @include icon-zoom-out-b();
  }

  .icon-zoom-in-a::before {
    @include icon-zoom-in-a();
  }

  .icon-zoom-in-b::before {
    @include icon-zoom-in-b();
  }

  .icon-payment-difference-a::before {
    @include icon-payment-difference-a();
  }

  .icon-payment-difference-b::before {
    @include icon-payment-difference-b();
  }

  .icon-replace-a::before {
    @include icon-replace-a();
  }

  .icon-replace-b::before {
    @include icon-replace-b();
  }

  .icon-business-a::before {
    @include icon-business-a();
  }

  .icon-business-b::before {
    @include icon-business-b();
  }

  .icon-no-file-a::before {
    @include icon-no-file-a();
  }

  .icon-no-file-b::before {
    @include icon-no-file-b();
  }
}
