@use 'sass:color';
@use './colors';
@use './fonts';
@use './radii';
@use './svg';

@import './shadows';
@import './fonts';

/**
 * Revised Design System.
 * B̶y̶ ̶A̶l̶b̶e̶r̶t̶ ̶&̶ ̶D̶i̶c̶k̶
 *
 * ...by anyone who works at Tellow. <3
 */

// Input
$tellow-input-border-color: colors.$gray-400;
$tellow-input-border: 1px solid $tellow-input-border-color;

$tellow-input-font-size: fonts.$size-base;
$tellow-input-padding-medium: 0.8rem 1rem;
$tellow-input-padding-large: 1rem 2rem;
$tellow-input-placeholder: colors.$gray-500;

$tellow-input-shadow-default: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset;
$tellow-input-shadow-focus: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, 0 0 0 3px colors.$green-10;

$tellow-input-default-bg: colors.$gray-100;
$tellow-input-default-bg-hover: colors.$gray-100;
$tellow-input-default-bg-focus: colors.$gray-100;
$tellow-input-default-color: colors.$gray-900;

$tellow-input-error-bg: color.adjust(colors.$red, $lightness: 25%);
$tellow-input-error-bg-hover: color.adjust(colors.$red, $lightness: 10%);
$tellow-input-error-bg-focus: color.adjust(colors.$red, $lightness: 10%);
$tellow-input-error-color: colors.$black;

// Alerts
$tellow-alert-padding-small: 0.3rem 1rem;
$tellow-alert-padding-medium: 0.5rem 1.5rem;
$tellow-alert-padding-large: 1rem 2rem;
$tellow-alert-border-radius: radii.$small;

$tellow-alert-info-color: colors.$text-muted;
$tellow-alert-info-background: colors.$gray-200;

$tellow-alert-attention-color: colors.$purple;
$tellow-alert-attention-background: color.adjust($tellow-alert-attention-color, $lightness: 48%);

$tellow-alert-warning-color: color.adjust(colors.$yellow, $lightness: -15%);
$tellow-alert-warning-background: color.adjust($tellow-alert-warning-color, $lightness: 51%);

$tellow-alert-error-color: colors.$red;
$tellow-alert-error-background: color.adjust($tellow-alert-error-color, $lightness: 35%);

$tellow-alert-success-color: color.adjust(colors.$green-subdue, $lightness: -8%);
$tellow-alert-success-background: color.adjust($tellow-alert-success-color, $lightness: 45%);
