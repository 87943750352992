/* stylelint-disable no-invalid-position-at-import-rule */

// Font declaration
@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Regular.woff2') format('woff2');
  font-display: block;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Bold.woff2') format('woff2');
  font-display: block;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Bold.woff2') format('woff2');
  font-display: block;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ES Rebond Grotesque';
  src: url('./assets/fonts/ESRebondGrotesque-Semibold.woff2') format('woff2');
  font-display: block;
  font-weight: 700;
  font-style: normal;
}

@import 'styles/design-system/variables';
@import 'styles/settings';
@import 'styles/foundation-utils';

// Toast
@import 'styles/toast';

// Helipopper / Tippy
@import 'styles/popper';
@import '~tippy.js/dist/tippy.css';

// Pikaday
@import '~pikaday/css/pikaday.css';
@import 'styles/elements/datepicker';

// Body level sass
@import 'styles/body';
@import 'styles/backgrounds';
@import 'styles/fonts';
@import 'styles/iconfont';

@include register-icons();

// Other
@import 'styles/helpers';
@import 'styles/animations/animations';
@import 'styles/hidden/office-cringe-manager';

// Override
@import 'styles/overrides/global';

// Elements
@import 'styles/elements/tooltip';
@import 'styles/elements/fnc-input-container';
@import 'styles/elements/tables';
@import 'styles/elements/modal';
@import 'styles/elements/account-overview';

/**
 * Fix for 'Google Translate'
 * as a feature in Chrome.
 */
* > font {
  color: unset;
}
