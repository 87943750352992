@use 'foundation-sites/scss/util/util' as foundation;
@use '../design-system/colors';
@use '../design-system/radii';

@import '../settings';
@import '../design-system/variables';

/* stylelint-disable selector-max-class */

$verticalRowPadding: 10px;

.reporting-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 1rem;

  &__search {
    margin-right: 0.5rem;
    flex-grow: 4;

    > tlw-input-container {
      > div.container.with-icon {
        margin: 0;

        input.tlw-formfield__input {
          padding: 0.5rem 1.5rem 0.5rem 3.25rem !important;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    tlw-icon-link-button {
      margin-bottom: 0;
      transition: opacity ease-in-out 0.2s;

      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }

      &:last-of-type {
        background-color: colors.$deepSeaGreen;
        border-color: colors.$deepSeaGreen;

        div.icon-wrapper > tlw-icon > svg-icon > svg {
          fill: colors.$electricGreen !important;
        }

        span.show-for-large {
          color: colors.$electricGreen;
        }

        &:hover {
          background-color: colors.$green-140;
          border-color: colors.$green-140;

          div.icon-wrapper > tlw-icon > svg-icon > svg {
            fill: colors.$white !important;
          }

          span.show-for-large {
            color: colors.$white;
          }
        }
      }

      @include foundation.breakpoint(991px down) {
        padding: 0.25rem;

        div.icon-wrapper {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.account-overview {
  .default-top-margin {
    margin-top: 1rem;
  }

  ::ng-deep {
    tlw-amount-range-selector {
      margin-top: -40px;
    }
  }

  fnc-input-action {
    margin-top: 10px;
    input {
      border: 1px solid colors.$gray-200 !important;
    }
  }

  tlw-icon-link-button {
    margin-top: 3px;
    float: right;
    margin-left: 10px;
  }

  [icon] {
    margin-top: 1px;
  }

  fnc-date-range-selector,
  tlw-amount-range-selector {
    fnc-input-container {
      .container {
        fnc-datepicker {
          input {
            border: 1px solid colors.$gray-200;
          }
        }
        tlw-amountpicker {
          div {
            input {
              border: 1px solid colors.$gray-200;
            }
          }
        }
      }
    }
    .period-selector-with-label {
      fnc-period-selector {
        .fnc-period-selector-container {
          button {
            border: 1px solid colors.$gray-200;
          }
        }
      }
    }
  }

  [snapToEdge] {
    .column-header {
      font-weight: bold;
    }

    .db-headers {
      margin-bottom: 10px;
    }

    &.snapped {
      position: fixed;
      top: $fixedHeaderHeight;

      * {
        color: colors.$white;
      }

      [icon] {
        color: colors.$blue;
      }

      fnc-input-container {
        label {
          color: colors.$white;
        }
        li {
          color: colors.$blue;
        }

        .list {
          .search-container {
            background-color: colors.$white;
            * {
              color: colors.$blue;
            }
            input {
              color: colors.$blue;
            }
          }
        }
      }

      fnc-input-action {
        fnc-icon {
          color: colors.$blue;
        }
      }

      tlw-icon-link-button {
        margin-bottom: 0;

        span {
          color: colors.$blue;
        }
      }

      fnc-date-range-selector,
      tlw-amount-range-selector {
        fnc-input-container {
          label {
            color: colors.$white;
          }

          * {
            color: colors.$blue;
          }
        }

        fnc-period-selector {
          * {
            color: colors.$blue;
          }
        }
      }

      ::ng-deep {
        label {
          color: colors.$white;
        }

        li {
          color: colors.$blue;
        }
      }

      .row {
        background-color: colors.$blue;
        padding: 5px;

        &.default-top-margin {
          margin-top: -40px;
        }
      }

      .currency {
        margin-right: 9px;
      }

      .last-header {
        border-bottom-right-radius: radii.$regular;
        border-bottom-left-radius: radii.$regular;
      }

      .group-header {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: relative;
      }

      .top-header::before {
        content: '';
        position: absolute;
        width: 2rem;
        height: 1rem;
        left: -47px;
        top: 0;
        border-top-right-radius: radii.$regular;
        box-shadow: 1rem 0 0 0 colors.$blue;
      }

      .top-header::after {
        content: '';
        position: absolute;
        width: 2rem;
        height: 1rem;
        right: -47px;
        top: 0;
        border-top-left-radius: radii.$regular;
        box-shadow: -1rem 0 0 0 colors.$blue;
      }
    }
  }

  .group {
    font-size: $__design2DefaultFontSize;

    &.no-margin {
      margin-top: 0;
    }

    margin-top: 60px;

    @include foundation.breakpoint(xxlarge) {
      font-size: $__design2P1Large;
    }

    .row {
      .columns {
        padding-bottom: $verticalRowPadding;
        padding-top: $verticalRowPadding;
      }
    }

    .group-header {
      .columns {
        font-weight: bold;
        font-size: 0.9rem !important;

        &.snapped {
          color: colors.$white;
          z-index: 2;
        }
      }

      background-color: colors.$gray-100;
      border-top-right-radius: radii.$regular;
      border-top-left-radius: radii.$regular;
    }

    .column-headers {
      .columns {
        padding-top: 0;
      }

      background-color: colors.$gray-100;
      border-bottom-right-radius: radii.$regular;
      border-bottom-left-radius: radii.$regular;
    }

    .section {
      .section-header {
        .columns {
          color: rgba(colors.$blue, 0.7);
          font-weight: bold;
          padding-bottom: 10px;
          padding-top: 30px;
        }

        border-bottom: 1px solid rgba(colors.$blue, 0.2);
      }
    }

    .group-total {
      border-top: 2px solid rgba(colors.$blue, 0.2);

      .columns:not(.total-title) {
        direction: rtl;
      }

      .columns {
        font-weight: bold;
      }
    }

    &.first {
      margin-top: 0;
    }
  }

  .balance {
    .columns:not(.balance-title) {
      direction: rtl;
    }

    &.clickable {
      cursor: pointer;

      .columns {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        transition: border $fastAnimation;
      }

      &:hover {
        .columns {
          border-bottom: 1px solid $lineColor;
          border-top: 1px solid $lineColor;
        }
      }
    }
  }

  .dark {
    background-color: colors.$gray-100;
  }

  .total {
    border-top: 2px solid colors.$gray-200;
    font-size: $__design2DefaultFontSize;

    @include foundation.breakpoint(xxlarge) {
      font-size: $__design2P1Large;
    }

    .columns {
      font-weight: bold;
      padding-bottom: $verticalRowPadding;
      padding-top: $verticalRowPadding;

      &:not(.total-title) {
        direction: rtl;
      }
    }
  }

  &.not-clickable {
    .balance.clickable {
      cursor: auto;

      &:hover {
        .columns {
          border-bottom: 1px solid transparent;
          border-top: 1px solid transparent;
        }
      }
    }
  }
}

::ng-deep {
  .ios {
    @include foundation.breakpoint(xlarge down) {
      .snapped {
        display: none !important;
      }
    }
  }
}
