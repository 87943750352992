@use 'foundation-sites/scss/util/util' as foundation;
@use '../design-system/colors';
@use '../design-system/fonts';
@use '../design-system/radii';
@use 'sass:color';
@use 'sass:math';

// DESIGN-SYSTEM-TODO: Replace.
@import '../design-system/variables';
@import '../settings';

/* stylelint-disable selector-max-class */

$hoverBgColor: colors.$white;

.table {
  .table-header {
    margin-bottom: -10px;
    padding: 0.25rem 0.75rem;
  }

  .row:not(.table-header):not(.table-title) {
    padding: 0.5rem 0.75rem;
    border-radius: radii.$small;
    border: 1px solid colors.$gray-200;
  }

  .row:not(.table-header):not(.table-title) + .row {
    margin-top: 0.25rem;
  }

  .row.is-last {
    margin-bottom: 0 !important;
  }

  *[fnc-table-group-title] {
    display: flex;
    align-items: center;

    font-size: fonts.$size-base;
    font-weight: fonts.$weight-semi-bold;
    text-transform: capitalize;
    margin-left: 1.5rem;

    > span {
      background: rgba(colors.$gray-200, 0.5);
      padding: 0.25rem 0.625rem;
      margin-left: 0.75rem;
      border-radius: radii.$small;
      font-size: fonts.$size-xxs;
      font-weight: fonts.$weight-bold;
    }

    will-change: opacity, transform;
    transition: all $defaultAnimation;
  }

  *[fnc-table-group] {
    position: relative;
    background: colors.$white;
    border: 1px solid colors.$gray-200;
    border-radius: radii.$regular;
    margin-bottom: 1.5rem;
    padding: 0.5rem;

    > div {
      border-color: transparent !important;
      position: relative;
    }

    transition: transform 125ms ease-in-out, opacity 180ms ease;
    will-change: opacity, transform;
  }

  .row {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    max-width: 100% !important;

    .column,
    .columns {
      align-self: center;
      font-size: $__design2DefaultFontSize;
      line-height: 15px;

      @include foundation.breakpoint(xxlarge) {
        font-size: $__design2P1Large;
      }

      padding-bottom: 6px;
      padding-top: 7px;

      fnc-button {
        margin: 0;
      }

      fnc-icon:not([disabled='true']) {
        cursor: pointer;
      }

      @include foundation.breakpoint(small only) {
        text-align: left;
      }

      &.remove {
        max-width: 60px;

        fnc-icon {
          position: relative;
          top: 6px;
        }
      }

      // Last input-divider of column should not have a border.
      &.input-divider:last-of-type {
        border: none;
      }
    }

    &[fnc-table-row] {
      transition: all $defaultAnimation;
      background: colors.$white;
      margin-bottom: 0.25rem;

      // This makes it so that all lines
      // are about as high as lines that
      // may have the 'af/bij' icons.
      min-height: 3.25rem;

      > div:not([title]) + div[title] {
        font-weight: fonts.$weight-semi-bold;
      }
    }

    &[hoverable] {
      transition: all $defaultAnimation;
    }

    &[warning] {
      $warning-purple: color.adjust(colors.$electricGreen, $lightness: -15%);

      border: 1px solid colors.$green-10 !important;
      background: colors.$green-10 !important;

      div:not([actionable]) {
        font-weight: fonts.$weight-semi-bold;
        color: $warning-purple !important;
      }

      div[actionable] {
        color: colors.$purple;
      }

      * > tlw-icon {
        fill: $warning-purple !important;
      }
    }

    &.raised {
      background-color: $hoverBgColor;
      box-shadow: 10px 10px 48px 9px rgba(colors.$black, 0.21);
      box-sizing: border-box;
      margin-bottom: 15px;
      margin-top: 15px;

      &:hover {
        opacity: 0.9;
      }
    }

    &[clickable] {
      cursor: pointer;
    }
  }

  .tx-rejected-state {
    opacity: 0.5 !important;
  }

  .tx-released-state,
  .tx-canceled-state {
    opacity: 0.8 !important;
  }

  //TODO: Still useful with design2? Remove otherwise
  &[lined] {
    .row {
      &:not(:last-of-type):not(.table-header) {
        border-bottom: 1px solid $lineColor;
      }
    }
  }

  &.design2 {
    //Display padding in small screen
    padding-left: math.div($main-layout-gutter-width, 2);

    @include foundation.breakpoint(xlarge) {
      padding-left: math.div($main-layout-gutter-width, 2);
    }

    .row {
      border-top: 1px solid $lineColor;
      margin-left: 0;

      &.table-header {
        max-width: 100% !important;
        padding: 0 1.5rem;
        width: 100%;

        border-top: 0;
        margin-bottom: 0;
        color: rgba(colors.$gray-600, 0.5);

        .column,
        .columns {
          font-weight: fonts.$weight-semi-bold;
          letter-spacing: fonts.$spacing-wider;

          @include foundation.breakpoint(xxlarge) {
            font-size: $__design2DefaultFontSize;
          }

          font-size: $__design2SmallFontSize;
        }
      }

      .columns,
      .column {
        padding-left: 0;

        h2 {
          border-top: 0;
        }

        listview-date {
          color: colors.$gray-600;
          font-variant-numeric: tabular-nums;
          font-weight: fonts.$weight-semi-bold;
        }

        quote-state > *,
        invoice-state > *,
        &.document-status > * {
          color: colors.$gray-600;
          font-weight: fonts.$weight-regular;
          letter-spacing: fonts.$spacing-narrow;
        }

        &.remove {
          padding: 0;

          fnc-icon {
            top: 2px;
          }
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      @include foundation.breakpoint(small down) {
        .column-right {
          text-align: right;
        }
      }

      fnc-action-icon {
        margin-left: -0.75rem;
      }

      &[hoverable] {
        transition: all $defaultAnimation;
        position: relative;

        &:hover {
          background-color: rgba(colors.$blue, 0.02);
          border: 1px solid rgba(colors.$blue, 0.02) !important;
        }

        tlw-dropdown:hover > button,
        tlw-dropdown > button.fab:hover {
          background: colors.$white !important;
        }
      }
    }

    .table-title {
      border-top: 0;
      padding: 0;

      .columns,
      .column {
        padding: 0;

        h2 {
          margin-bottom: 9px;
          margin-top: 0;
        }
      }
    }
  }

  &.white-background {
    background: colors.$white;
    border: 1px solid colors.$gray-200;
    border-radius: radii.$regular;
    padding: 0.5rem 0;
    margin: 0 1.25rem;

    .table-title {
      margin: 0.5rem 1.5rem;
    }

    .table-header {
      margin-bottom: 0.5rem !important;
    }

    *[fnc-table-group-title] {
      margin: 1rem 1.5rem;
      font-size: fonts.$size-base;
    }

    *[fnc-table-group] {
      margin-bottom: 0;
      padding: 0 0.5rem;
      background-color: unset;
      border: none;

      .row.top-align {
        .column,
        .columns {
          align-self: flex-start;
        }
      }
    }
  }
}
