@use './design-system/shadows';
@use './design-system/colors';
@use './design-system/fonts';
@use './design-system/radii';

@mixin theme-color($name, $color) {
  &[#{$name}]:not([disabled]):focus,
  &[#{$name}]:not([disabled]):hover {
    background: rgba($color, 0.05);
    color: $color;

    // No fill = has stroke.
    > svg[fill='none'] > path {
      color: $color;
      stroke: $color;
    }

    // Double negative. Not fill="none" = has fill.
    > svg:not([fill='none']) > path {
      color: $color;
      fill: $color;
    }
  }
}

.tlw-tiny-padding .tippy-box[data-theme^='tlw'] > .tippy-content > div > * {
  padding: 0 0.375rem;
}

.tlw-small .tippy-box {
  min-width: unset !important;
}

.tippy-box[data-theme^='tlw'] {
  transition: 150ms ease-in-out !important;
  border-radius: radii.$small;
  box-shadow: shadows.$small;
  background: colors.$white;
  min-width: 12rem;
}

.tippy-box[data-theme^='tlw'] > .tippy-backdrop {
  background-color: colors.$white;
}

.tippy-box[data-theme^='tlw'] > .tippy-content > div {
  margin: 0.25rem 0;

  & > * + * {
    margin-top: 0.25rem;
  }

  > h3 {
    color: colors.$purple;
    font-size: fonts.$size-sm;
    font-weight: fonts.$weight-bold;
    letter-spacing: fonts.$spacing-narrow;
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: radii.$small;
    color: colors.$gray-900;
    cursor: pointer;

    width: 100%;
    margin: unset;
    padding: 0.5rem 1rem;

    font-size: fonts.$size-xs;
    font-weight: fonts.$weight-regular;
    line-height: fonts.$line-height-base;

    // Theming per available color
    @include theme-color('blue', colors.$blue-light);
    @include theme-color('purple', colors.$purple);
    @include theme-color('green', colors.$green);
    @include theme-color('orange', colors.$orange);
    @include theme-color('red', colors.$red);

    // Default color
    &:not([disabled]):focus {
      background: colors.$gray-200;
      color: colors.$gray-900;

      > svg > path[stroke-width] {
        stroke: colors.$gray-900;
      }

      > svg > path[fill] {
        fill: colors.$gray-900;
      }
    }

    &:focus {
      outline: 0;
    }

    tlw-icon {
      margin-right: 0.5rem;
    }

    &.active {
      color: colors.$purple;
      background: colors.$green-10;

      > svg {
        stroke: colors.$purple;
      }
    }

    svg {
      height: 1.25rem;
      margin-right: 0.5rem;
      color: currentColor;
    }

    &[disabled],
    &[disabled]:hover {
      cursor: not-allowed !important;
      color: rgba(colors.$gray-900, 0.25) !important;
      background: transparent !important;

      > svg {
        stroke: rgba(colors.$gray-900, 0.25) !important;
      }
    }
  }
}

.tippy-box[data-theme='tlw-unit'] > .tippy-content {
  padding: 0.15rem !important;

  > div {
    margin: 0.15rem !important;

    & > * + * {
      margin-top: 0.15rem;
    }

    > * {
      border-radius: calc(radii.$small - 0.15rem);
      padding: 0.325rem 1rem;
      line-height: 1.35rem;
    }
  }
}

// Animation
.tippy-box[data-animation='shift-away'][data-state='hidden'] {
  opacity: 0;
}

.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='top'] {
  transform: translateY(5px);
}

.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='bottom'] {
  transform: translateY(-5px);
}

.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='left'] {
  transform: translateX(5px);
}

.tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='right'] {
  transform: translateX(-5px);
}
