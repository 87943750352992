@use '../../styles/design-system/colors';

:root {
  /**
 * '1234511' so it goes over:
 * 1. - Tippy's '999' value
 * 2. - Userflow's '1234510' value
 */
  --modal-z-index: 1234511;
}

/**
 *
 */
%backdrop {
  transition: opacity 150ms ease-in-out;
  background-color: rgba(colors.$black, 0.3);

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &.dark-overlay {
    background-color: rgba(colors.$black, 0.8);
  }

  &.blur {
    backdrop-filter: blur(3px);
  }
}

/**
 *
 */
%z-index {
  z-index: var(--modal-z-index);
  isolation: isolate;
}

/**
 *
 */
%highest-z-index {
  z-index: calc(var(--modal-z-index) + 1);
  isolation: isolate;
}
