@use './colors';

/**
 * Strokes
 */
svg[stroke-purple],
path[stroke-purple],
circle[stroke-purple] {
  stroke: colors.$purple;
}

svg[stroke-red],
path[stroke-red],
circle[stroke-red] {
  stroke: colors.$red;
}

svg[stroke-white],
path[stroke-white],
circle[stroke-white] {
  stroke: colors.$white;
}

svg[stroke-green],
path[stroke-green],
circle[stroke-green] {
  stroke: colors.$green;
}

svg[stroke-orange],
path[stroke-orange],
circle[stroke-orange] {
  stroke: colors.$orange;
}

/**
 * Fills
 */
svg[fill-purple],
path[fill-purple],
circle[fill-purple] {
  fill: colors.$purple;
}

svg[fill-blue],
path[fill-blue],
circle[fill-blue] {
  fill: colors.$blue-light;
}

svg[fill-red],
path[fill-red],
circle[fill-red] {
  fill: colors.$red;
}

svg[fill-white],
path[fill-white],
circle[fill-white] {
  fill: colors.$white;
}

svg[fill-green],
path[fill-green],
circle[fill-green] {
  fill: colors.$green;
}

svg[fill-orange],
path[fill-orange],
circle[fill-orange] {
  fill: colors.$orange;
}
