@use 'foundation-sites/scss/util/util' as foundation;
@use './design-system/colors';
@use './design-system/radii';
@use './settings';
@use 'sass:math';

@import './design-system/variables';

$columns: 10;

[blue],
.color.blue {
  color: colors.$blue-light;
}

[green],
.color.green {
  color: colors.$green;
}

[green-subdue],
.color.green-subdue {
  color: colors.$green-subdue;
}

[yellow],
.color.yellow {
  color: colors.$yellow;
}

[orange],
.color.orange {
  color: colors.$orange;
}

[red],
.color.red {
  color: colors.$red;
}

[purple],
.color.purple {
  color: colors.$purple;
}

[lightGreen],
.color.lightGreen {
  color: settings.$lightGreen;
}

[brown],
.color.brown {
  color: settings.$brown;
}

[grey],
.color.grey {
  color: colors.$gray-900;
}

[white],
.color.white {
  color: colors.$white;
}

%pill,
.pill {
  font-weight: 600;
  padding: 0.1rem 0.5rem;
  border-radius: radii.$small;
}

// Backgrounds
.color.bg-green-a10 {
  @extend %pill;
  background: rgba(colors.$green, 0.1);
}

.color.bg-red-a10 {
  @extend %pill;
  background: rgba(colors.$red, 0.05);
}

.color.bg-yellow-a10 {
  @extend %pill;
  background: rgba(colors.$yellow, 0.1);
}

.color.bg-orange-a10 {
  @extend %pill;
  background: rgba(colors.$orange, 0.1);
}

.color.bg-purple-a10 {
  @extend %pill;
  background: colors.$green-10;
}

[relative] {
  position: relative;
}

[no-top-margin] {
  margin-top: 0 !important;
}

[no-margin] {
  margin: 0 !important;
}

[default-top-margin] {
  margin-top: settings.$defaultTopMargin !important;
}

[default-bottom-margin] {
  margin-bottom: settings.$defaultBottomMargin !important;
}

[default-half-top-margin] {
  margin-top: (settings.$defaultBottomMargin * 1.5) !important;
}

[default-half-bottom-margin] {
  margin-bottom: (settings.$defaultBottomMargin * 1.5) !important;
}

[double-top-margin] {
  margin-top: (settings.$defaultTopMargin * 2) !important;
}

[double-bottom-margin] {
  margin-bottom: (settings.$defaultTopMargin * 2) !important;
}

[text-overflow-ellipsis] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[bold] {
  font-weight: bold;
}

[float-right] {
  float: right;
}

[float-left] {
  float: left;
}

[extra-gutter] {
  padding-left: settings.$main-layout-gutter-width;
  padding-right: settings.$main-layout-gutter-width;
}

@include foundation.breakpoint(large up) {
  .hide-above-tablet {
    display: none !important;
  }
}
@include foundation.breakpoint(medium down) {
  .hide-for-tablet {
    display: none !important;
  }
}
@include foundation.breakpoint(medium up) {
  .hide-above-mobile {
    display: none !important;
  }
}
@include foundation.breakpoint(small only) {
  .hide-for-mobile {
    display: none !important;
  }

  .bold {
    font-weight: 600;
  }

  .mobile-flex-row {
    display: flex;
    padding: 10px 0;

    .columns {
      line-height: 5px !important;
    }

    .round {
      margin-left: -5px !important;
      margin-right: 10px !important;
    }
  }

  /* stylelint-disable */
  .safari,
  .ios {
    .row.medium-unstack {
      .columns,
      .column {
        flex: 1 1 30%;

        &.registration-unstack {
          flex: 1 1 100%;
        }
      }
    }
  }

  @for $i from 1 through $columns {
    .order-mobile-#{$i} {
      order: $i;
    }
  }
}

strong,
b,
u {
  color: inherit;
}

u {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@function rem-calc($size) {
  $remSize: math.div($size, 16);
  @return #{$remSize}rem;
}
