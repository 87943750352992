@use 'foundation-sites/scss/util/util' as foundation;
@use '../design-system/shadows';
@use '../design-system/colors';
@use '../design-system/fonts';
@use '../design-system/radii';

// DESIGN-SYSTEM-TODO: Replace.
@import '../design-system/variables';
@import '../design-system/mixins/alerts';
@import '../settings';
@import '../iconfont';

$textMarginBottom: 14px;

@mixin validation() {
  input.mdl-textfield__input,
  textarea.mdl-textfield__input {
    border-color: colors.$red !important;
    @include tellow-alert-shadow-error();

    &:focus {
      @include tellow-alert-outline-error();
    }

    &::placeholder {
      color: colors.$red;
    }
  }

  fnc-datepicker {
    color: colors.$red;

    input {
      color: colors.$red;
    }
  }

  fnc-datepicker + label {
    color: colors.$red;
  }

  label.mdl-textfield__label {
    color: colors.$red;
  }

  fnc-validator-messages {
    &.show {
      opacity: 1;
      transform: scale(1) translate(0, 0);
      visibility: visible;
    }
  }

  .container.is-focused,
  .container[is-focused] {
    fnc-validator-messages.show {
      opacity: 1;
      transform: scale(0) translate(0, 0);
      visibility: hidden;
    }
  }

  &.has-currency-value {
    .container::before {
      color: colors.$red;
    }
  }
}

fnc-input-container {
  display: block;
  position: relative;
  width: 100%;

  &[adhere] {
    width: unset;
  }

  // TMW20 Override
  &[modern] {
    &[with-icon] {
      .container {
        input.mdl-textfield__input,
        textarea.mdl-textfield__input {
          padding-left: calc(0.75rem + 12px + 0.625rem);
        }

        span[icon] {
          position: absolute;
          left: 0.75rem;
          z-index: 2;
          display: flex;
          text-align: center;
          pointer-events: none;

          // To be inherited by svg icon.
          fill: colors.$gray-400;
        }
      }
    }

    &.is-slider-container tlw-slider {
      border-bottom: unset !important;
    }

    .container {
      margin-top: 0.25rem;
      border-radius: radii.$small;

      display: flex;
      align-items: center;
      position: relative;

      padding-bottom: 0;

      // Hide icon when not in use.
      span[icon] {
        pointer-events: none;
        display: none;
      }

      // Top
      &.fixed-label > label {
        top: -0.3rem !important;
      }

      // Label
      &.is-dirty,
      &.is-focused {
        label.mdl-textfield__label {
          top: -0.3rem !important;
          text-overflow: ellipsis;
        }
      }

      label.mdl-textfield__label {
        left: 0rem;
        top: 2.05rem;
      }

      input.mdl-textfield__input {
        line-height: 1;
      }

      // Input
      input.mdl-textfield__input,
      textarea.mdl-textfield__input {
        min-height: 2.5rem;
        font-size: fonts.$size-sm;

        appearance: none;
        display: block;
        width: 100%;

        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &[type='date'] {
          display: block;
          text-align: center;

          padding-left: 0 !important;
          padding-right: 0 !important;

          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator,
          &::-webkit-clear-button {
            padding-right: 1rem;
          }
        }

        background: colors.$white;
        box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset !important;
        border: $tellow-input-border !important;
        border-radius: radii.$small;

        &::placeholder {
          color: colors.$gray-400;
        }

        &:hover {
          border-color: colors.$deepSeaGreen !important;
        }

        &:focus {
          outline: none;
          box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, shadows.$outline-input !important;
          border-color: colors.$electricGreen !important;

          &[blue],
          &.blue {
            outline: none;
            box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, shadows.$outline-input !important;
            border-color: colors.$electricGreen !important;
          }
        }

        &:not(.ng-pristine):not(.is-focussed).ng-invalid {
          box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, 0 0 0 3px rgba(colors.$red, 0.2) !important;
          border-color: colors.$red !important;
        }

        transition: 150ms ease-in-out;
      }

      &.no-label {
        margin-top: 5px;
        padding-top: unset;
      }

      &.no-top {
        margin-top: unset !important;
        padding-top: unset;
      }
    }

    // Put the label text in the center of the box.
    &.is-select-container,
    &.is-radio-container {
      .container {
        label.mdl-textfield__label {
          top: 30px;
        }
      }
    }
  }
  // End of override.

  .container {
    margin-top: 0;
    padding-bottom: 1px;
    width: 100%;

    input.mdl-textfield__input,
    textarea.mdl-textfield__input {
      border: 0;
      color: colors.$blue;
      font-weight: fonts.$weight-regular;
      font-size: $__design2DefaultFontSize;

      // No horizontal resize.
      resize: vertical;

      // Animate transistions.
      transition: 150ms ease-in-out;

      @include foundation.breakpoint(xxlarge) {
        font-size: $__design2P1Large;
      }

      &::placeholder {
        color: transparent;
        font-weight: fonts.$weight-regular;
        font-size: $__design2DefaultFontSize;
        transition: all 0.3s $defaultTiming;

        @include foundation.breakpoint(xxlarge) {
          font-size: $__design2P1Large;
        }
      }

      &[disabled] {
        color: rgba(colors.$blue, 0.3);
      }

      &:focus,
      &:active {
        &::placeholder {
          color: rgba(colors.$blue, 0.7);
        }

        &[disabled] {
          &::placeholder {
            color: transparent;
          }
        }
      }

      // readonly inputs.
      &.read-only {
        color: colors.$text-readonly;
        font-weight: fonts.$weight-bold;
        cursor: default;
      }
    }

    label.mdl-textfield__label {
      color: colors.$gray-900 !important;
      font-family: $font-family !important;
      font-size: $design2LabelSize;
      font-weight: fonts.$weight-regular;
      transition-delay: 0.2s;

      &::after {
        display: none;
      }

      &[placeholder] {
        top: 4px;
      }
    }

    //MDL fixes
    &[is-focused],
    &.is-focused,
    &.is-dirty {
      input.mdl-textfield__input {
        &::placeholder {
          transition: all 0.3s $defaultTiming 0.1s;
        }
      }

      label.mdl-textfield__label {
        color: colors.$gray-900;
        font-size: $__design2DefaultFontSize;
        transition-delay: 0s;
      }
    }

    &.is-invalid {
      input.mdl-textfield__input,
      textarea.mdl-textfield__input {
        color: colors.$blue;
      }

      label.mdl-textfield__label {
        color: colors.$gray-900;
      }
    }

    &.fixed-label {
      label.mdl-textfield__label {
        top: 4px;

        &[fixed] {
          top: -0.3rem !important;
        }
        &[double] {
          top: -1.2rem !important;
        }
      }

      input.mdl-textfield__input,
      textarea.mdl-textfield__input {
        &::placeholder {
          color: $disabledColor;
        }
      }
    }

    &.no-label {
      margin-top: 0;
      padding-top: 5px;

      fnc-button {
        margin-top: 15px;
      }

      fnc-toggle.mdl-textfield__input {
        fnc-button {
          margin-top: 0;
        }
      }

      input.mdl-textfield__input,
      textarea.mdl-textfield__input {
        &::placeholder {
          color: $disabledColor;
        }
      }
    }

    &.read-only {
      input.mdl-textfield__input {
        border: 0;
        color: colors.$blue;
        cursor: default;
      }
    }
  }

  fnc-toggle.mdl-textfield__input {
    border-bottom: 0;
    display: block;
    padding: 0;
    width: auto;

    button {
      bottom: 0;
      top: 0;
    }
  }

  // Validation
  &.should-validate.ng-touched.ng-invalid {
    @include validation();
  }

  // Disabled fixes
  &.disabled {
    &.ng-touched.ng-invalid {
      fnc-validator-messages.show {
        opacity: 0;
        transform: scale(0);
        visibility: hidden;
      }
    }

    .container label.mdl-textfield__label {
      color: $disabledColor !important;
    }
  }

  // Chrome autofill fixes
  @-webkit-keyframes autofill {
    to {
      background: transparent;
      color: rgba(colors.$blue, 0.7);
    }
  }

  input:-webkit-autofill {
    -webkit-animation-fill-mode: both;
    -webkit-animation-name: autofill;
    color: colors.$blue !important;
  }

  // Select
  &.is-select-container,
  &.is-radio-container {
    .container {
      label.mdl-textfield__label {
        max-width: calc(100% - 20px);
        overflow: hidden;
        text-overflow: ellipsis;
        top: 23px;

        &::after {
          display: none;
        }
      }

      &.is-dirty,
      &.is-focused {
        label.mdl-textfield__label {
          top: 4px;
        }
      }

      fnc-select,
      fnc-radio-group,
      fnc-select-input {
        &.mdl-textfield__input {
          border-bottom: 0;

          &[disabled] {
            .fnc-select-value-inner {
              color: $disabledColor;
            }
          }
        }
      }
    }

    &.is-radio-container .container {
      label.mdl-textfield__label {
        top: -10px !important;
      }
    }
  }

  // Button fixes
  .container.mdl-textfield .mdl-button {
    position: relative;
  }
}

form.ng-submitted {
  fnc-input-container.should-validate.ng-invalid {
    @include validation();
  }
}

// .column.input-divider,
// .columns.input-divider {
//   border-bottom: 1px solid colors.$line-light;
// }
