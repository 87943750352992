@use './colors';
@use 'sass:math';

// Spacing
$rem-to-px: 16;

// Utility shadows
$outline: 0 0 0 3px rgba(colors.$purple-subdue, 0.2);
$outline-input: 0 0 0 3px colors.$green-10;

// Normal depths
$small: 0 math.div(1rem, $rem-to-px) math.div(12rem, $rem-to-px) math.div(-1rem, $rem-to-px) rgba(colors.$blue, 0.1);
$extra-small: 0 0 0 1px rgba(colors.$gray-900, 0.02);

// Card specific
$card: 0 3px 0.85rem 0 colors.$gray-200;
$soft-card: 0 3px 2rem 0 rgba(colors.$gray-500, 0.15);

// Roll-your-own-shadows.™
// Handy for i.e. error shadows.
@mixin extra-small($color, $opacity: 0.02) {
  box-shadow: 0 0 0 1px rgba($color, $opacity);
}

@mixin outline($color, $size: 1px) {
  box-shadow: 0 0 0 $size $color !important;
}

@mixin soft($color: colors.$purple, $opacity: 1, $size: 0.85rem, $offset: 3px) {
  box-shadow: 0 $offset $size 0 rgba($color, $opacity);
}

@mixin smooth($color: colors.$purple) {
  box-shadow: 1.2px 2.3px 2.2px rgba($color, 0.014), 3px 5.5px 5.3px rgba($color, 0.02), 5.6px 10.3px 10px rgba($color, 0.025),
    10.1px 18.3px 17.9px rgba($color, 0.03), 18.8px 34.3px 33.4px rgba($color, 0.036), 45px 82px 80px rgba($color, 0.05);
}
