@import 'foundation-sites/scss/util/util';

// 1. Global
// ---------
$foundation-palette: (
  primary: #2199e8,
  secondary: #777777,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);

/* stylelint-disable-next-line value-keyword-case */
$body-font-family: 'Aeonik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Roboto, Arial, 'Noto Sans', sans-serif;
$header-font-family: $body-font-family;
$body-antialiased: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 992px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large xlarge xxlarge);
