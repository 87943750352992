@use '../design-system/shadows';
@use '../design-system/colors';
@use '../design-system/fonts';
@use '../design-system/radii';

/**
 * Locked transactions
 * are blocked by this.
 */
.tx-locked-state {
  padding: 0;
  user-select: none !important;
  pointer-events: none !important;
  background: colors.$white !important;
  border: 1px solid colors.$gray-200 !important;

  &,
  & > *,
  * > svg,
  listview-date {
    fill: colors.$gray-500 !important;
    color: colors.$gray-500 !important;
    font-weight: fonts.$weight-semi-bold !important;
  }
}

.tx-locked-dropdown {
  display: flex;
  position: relative;
  align-items: center;

  user-select: none !important;
  pointer-events: none !important;
  color: colors.$gray-500 !important;

  svg {
    fill: colors.$gray-400;
    margin-right: 0.5rem;
    height: 0.8rem;
    width: 0.8rem;
  }
}

// Pixel
.global-conversion-pixel {
  border: 0;
  width: 1px;
  height: 1px;
}

// Admin warning
.global-super-admin-warning {
  outline: 2px solid colors.$red !important;
  outline-offset: 2px;

  position: relative;
  // margin-top: 2rem !important;

  &::before {
    content: '\00a0 ⚠️ Admin Only View \00a0';
    position: absolute;

    max-width: max(0px, calc((100% - 120px) * 999));
    overflow: hidden;

    top: calc(-1rem + -0.5rem);
    height: 1rem;
    left: 0;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.2rem;

    font-size: 0.5rem;
    color: colors.$white;
    background: colors.$red;
    font-weight: fonts.$weight-bold;
  }
}
