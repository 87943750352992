/* stylelint-disable value-keyword-case */
$family: 'Aeonik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Roboto, Arial, 'Noto Sans', sans-serif;
$display: 'ES Rebond Grotesque', $family;
$monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

// Font weights
$weight-extra-light: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-semi-bold: 500;
$weight-bold: 600;
$weight-heavy: 800;

// Font sizes
$size-xxs: 0.625rem;
$size-xs: 0.8rem;
$size-sm: 0.9rem;
$size-base: 1rem;
$size-lg: 1.25rem;
$size-xl: 1.5rem;
$size-2xl: 2rem;
$size-3xl: 2.5rem;

$line-height-extra-small: 1;
$line-height-small: 1.15;
$line-height-medium: 1.3;
$line-height-base: 1.5;
$line-height-large: 1.65;
$line-height-extra-large: 2;

// Spacing
$spacing-narrow: -0.0125em;
$spacing-wider: 0.0125em;
