@use './design-system/colors';
@use 'sass:math';

@import './design-system/variables';
@import 'foundation-utils';
@import './template';

/* stylelint-disable-next-line value-keyword-case */
$font-family: 'Aeonik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Roboto, Arial, 'Noto Sans', sans-serif;
$iconFont: 'fynciconfont';

$lineColor: rgba(colors.$blue, 0.2);
$disabledColor: rgba(colors.$blue, 0.6);

// Buttons
$buttonHeight: 1.75rem;
$buttonTopMargin: 2rem;
$buttonDisabledColor: rgba(colors.$white, 0.5);

// Animations
$defaultTiming: cubic-bezier(0, 0, 0.17, 1.08);
$defaultAnimation: 0.3s $defaultTiming;
$fastAnimation: 0.15s $defaultTiming;
$slowAnimation: 0.5s $defaultTiming;
$secondAnimation: 1s $defaultTiming;

// Other settings
$gutterWidth: 20px;
$max-content-width: 1200px;

$main-layout-gutter-width: 40px;

// Design2
$defaultTopMargin: 15px;
$defaultBottomMargin: 15px;

// Fonts
$__design2DefaultFontSize: 12px;
$__design2SmallFontSize: 10px;
$design2LabelSize: 12px;

// Input
$__design2InputSize: 14px;
$design2LabelColor: rgba(colors.$gray-500, 0.8);
$__design2InputDividerColor: rgba(colors.$blue, 0.3);

// Headers / titles / p's
$__design2H1: 40px;
$__design2H2: 22px;
$__designP1: 12px;
$__designP2: 10px;

// Page header/sub/content
$__design2PageHeaderHeight: 50px; // Used to be: `250px`
$__design2PageHeaderTopMargin: 14px; // (design 48px, top container height = 34) 48-34= 14 remaining px

$__design2PageSubheaderHeight: 32px;
$pageSubheaderShadow: 0 10px 30px 0 rgba(colors.$black, 0.2);

// Other
$__design2RowWidth: calc(100% + #{math.div($main-layout-gutter-width, 2)});
$fixedHeaderHeight: 34px;

// XL fonts use on > xlarge
$__design2H1Large: 44px;
$__design2H2Large: 26px;
$__design2P1Large: 14px;
$__design2P2Large: 12px;
