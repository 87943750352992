// Border radii
$tiny: 0.162rem;
$extra-small: 0.325rem;
$small: 0.625rem;
$medium: 0.8rem;
$regular: 1rem;
$large: 2rem;

// Specifics
$card: $small;

/* stylelint-disable-next-line unit-disallowed-list */
$full: 9999px;
