@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

$tho: mat;
$mas: rix;

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: capitalize($tho) '+' $mas;
  src: url('../../assets/fonts/hidden.woff') format('woff');
}

:root .m4tr1x {
  background: #000000 !important;
  filter: invert(1) contrast(1.25) hue-rotate(395deg);

  * {
    text-transform: capitalize;

    /* stylelint-disable */
    font-family: capitalize($tho) + $mas, sans-serif;
    /* stylelint-enable */
  }

  tlw-messagebox {
    display: none;
  }

  .content {
    filter: grayscale(1);
  }

  &::before {
    content: 'Wake up neo...';
    animation: blink 2.5s infinite;
    background: #c0c0c0;

    padding: 0.25rem 0.5rem;
    z-index: 9999999999;
    position: absolute;
    top: 1.15rem;
    left: 5rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    opacity: 0.25;
    user-select: none;
    pointer-events: none;
    mix-blend-mode: multiply;

    filter: invert(1);
    z-index: 9999999999;
    backdrop-filter: invert(1);
    background-image: url('https://media.giphy.com/media/smzfl3E7a4iHK/giphy.gif');
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
}
