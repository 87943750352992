@use 'sass:string';
@use 'sass:math';

/**
 * Proposed grayscale,
 * not yet in use.
 */
// $grayscale: (
//   '100': rgb(251, 251, 251),
//   '200': rgb(222, 230, 241),
//   '400': rgb(196, 207, 222),
//   '500': rgb(156, 173, 196),
//   '600': rgb(63, 78, 100),
// );

$colors: (
  'green': rgb(17, 86, 93),
  'blue': rgb(80, 199, 236),
  'yellow': rgb(255, 204, 104),
);
