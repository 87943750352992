@use 'design-system/colors';

@import 'design-system/variables';

html,
body {
  height: 100%;

  // Avoid 300ms click delay
  button,
  .button {
    touch-action: manipulation;
  }
}

body {
  margin: 0;
  background: colors.$gray-200;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: auto;
  background-color: colors.$gray-200;
  -webkit-tap-highlight-color: rgba(colors.$black, 0);
}

div.divider {
  width: 100%;
  height: rem-calc(1px);
  background-color: rgba(colors.$black, 0.1);
}

div.spacer {
  margin-top: 5rem;
}

* {
  outline: none !important;
}
