@use './theme/default' as theme;
@use '@hellotellow/design/colors' as colors;
@use 'sass:map';

/**
 * Grayscale
 */
$white: colors.$white-100;
$gray-50: #fdfdfd;
$gray-100: colors.$gray-10;
$gray-200: colors.$gray-20;
$gray-400: colors.$gray-40;
$gray-500: colors.$gray-50;
$gray-600: colors.$gray-60;
$gray-900: colors.$gray-90;

$black: colors.$black-100;
$blue: colors.$black-100;

$purple: colors.$brand-DeepSeaGreen;
$green: colors.$green-100;
$red: colors.$red-100;
$orange: #ffbb40;

/**
 * Ageras brand colors
 */
$neonGreen: colors.$brand-ElectricGreen;
$darkGreen: colors.$brand-DeepSeaGreen;

$electricGreen: colors.$brand-ElectricGreen;
$deepSeaGreen: colors.$brand-DeepSeaGreen;
$lavender: colors.$brand-Lavender;
$dayGrey: colors.$brand-DayGrey;
$activeBorderColor: #00e383;

/**
  * Ageras colors
  */
$green-140: colors.$green-140;
$green-10: #d1fadf;
$red-120: colors.$red-120;
$red-10: colors.$red-10;
$red-05: colors.$red-05;
$purple-20: colors.$purple-20;

/* To be migrated to @hellotellow/design/colors yet */
$blue-light: map.get(theme.$colors, 'blue');
$yellow: map.get(theme.$colors, 'yellow');

/**
 * @deprecate these.
 * With better color shades,
 * there is no need for 'subdue'.
 *
 * Just forward them for now.
 */
$green-subdue: $green;
$orange-subdue: $orange;
$blue-light-subdue: $blue-light;
$purple-subdue: $purple;
$red-subdue: $red;

/**
 * Semantic / derived colors.
 * Remain to be useful, make
 * more of these later on.
 */
$text-muted: $gray-600;
$text-readonly: $gray-500;
$text-disabled: $gray-400;
$error: $red;
$background: $gray-100;
$line-light: $gray-200;
