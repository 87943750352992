@use '../fonts';
@use '../colors';
@import '../variables';

@mixin tellow-alert-info($padding: $tellow-alert-padding-medium, $color: $tellow-alert-info-color) {
  @include tellow-alert($color, $tellow-alert-info-background, $padding);
}
@mixin tellow-alert-warning($padding: $tellow-alert-padding-medium, $color: $tellow-alert-warning-color) {
  @include tellow-alert($color, $tellow-alert-warning-background, $padding);
}
@mixin tellow-alert-attention($padding: $tellow-alert-padding-medium, $color: $tellow-alert-attention-color) {
  @include tellow-alert($color, $tellow-alert-attention-background, $padding);
}
@mixin tellow-alert-error($padding: $tellow-alert-padding-medium, $color: $tellow-alert-error-color) {
  @include tellow-alert($color, $tellow-alert-error-background, $padding);
}
@mixin tellow-alert-success($padding: $tellow-alert-padding-medium, $color: $tellow-alert-success-color) {
  @include tellow-alert($color, $tellow-alert-success-background, $padding);
}
@mixin tellow-alert($color, $background, $padding: $tellow-alert-padding-medium) {
  font-weight: fonts.$weight-semi-bold;
  text-align: center;
  padding: $padding;
  border-radius: $tellow-alert-border-radius;
  color: $color !important;
  background: $background;
  line-height: 1.5;

  a {
    color: currentColor;
    text-decoration: underline;
  }
}

// Input validations: shadows
@mixin tellow-alert-shadow-error() {
  @include tellow-alert-shadow($tellow-alert-error-color);
}
@mixin tellow-alert-shadow($color: $tellow-alert-error-color) {
  box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, 0 0 0 1px rgba($color, 0.05);
}

// Input validations: shadows
@mixin tellow-alert-outline-error() {
  @include tellow-alert-outline($tellow-alert-error-color);
}
@mixin tellow-alert-outline($color: $tellow-alert-error-color) {
  box-shadow: rgba(colors.$gray-600, 0.3) 0px 2px 4px inset, 0 0 0 3px rgba($color, 0.2) !important;
}
