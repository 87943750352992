@use 'foundation-sites/scss/util/util' as foundation;
@use 'design-system/colors';

@import 'design-system/variables';

body {
  &.page--bg-dynamic,
  &.page--bg-dark,
  &.page--bg-light {
    background: colors.$background;
    background-size: cover;
  }

  &.page--bg-dark {
    background-color: colors.$blue;
  }

  // dark mode preferred
  @media (prefers-color-scheme: dark) {
    &.page--bg-dynamic {
      background-color: colors.$blue;
    }
  }
}
