@use '../design-system/shadows';
@use '../design-system/colors';
@use '../design-system/fonts';
@use '../design-system/radii';

/* stylelint-disable selector-max-class */

// Variables
$color-text-disabled: colors.$gray-400;
$color-text: colors.$gray-600;

/**
 * Theme for the Pikaday datepicker.
 * Styles have to happen at a top level,
 * because they are not applied otherwise
 * (i.e. _not_ in component's host SCSS).
 */

.pika-single {
  border: 1px solid colors.$gray-200;
  border-radius: radii.$small;
  margin-top: 0.25rem;

  opacity: 1;
  transform: scale(1) translateZ(0);
  visibility: visible;

  &.is-hidden {
    opacity: 0;
    transform-origin: top left;
    transform: scale(0.98) translateZ(0);
    transition: transform 300ms ease, opacity 300ms ease, visibility 300ms ease;
    will-change: transform, opacity, visibility;
    visibility: hidden;

    // Unset because foundation sets this style, too.
    display: unset !important;
  }

  &.is-bound {
    @include shadows.soft(colors.$gray-200);
  }

  .pika-lendar {
    margin: 1rem;

    .pika-title {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;
      margin-left: 0.125rem;
      margin-top: 0.25rem;

      .pika-label {
        font-size: fonts.$size-lg;
        border-radius: radii.$small;
        margin-right: 0.25rem;
        line-height: 1.35;

        padding: 0 0.125em;
        font-weight: fonts.$weight-semi-bold;
        letter-spacing: fonts.$spacing-narrow;
        transition: 50ms ease-in-out;

        &::after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath d='M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: 100% 50%;
          display: inline-block;
          width: 1.25rem;
          height: 0.9rem;
        }

        .pika-select {
          line-height: 1.35;
          padding: 0 0.125em;
          font-size: fonts.$size-sm;
        }

        &:hover {
          color: colors.$gray-600;
        }
      }

      /* Next/Previous */
      .pika-prev,
      .pika-next {
        display: none;

        // position: absolute;
        // outline: none;
        // padding: 0;
        // width: 1.5rem;
        // height: 1.5rem;
        // top: 1rem;
        // display: inline-block;
        // margin-top: 0;
        // cursor: pointer;
        // text-indent: -9999px;
        // white-space: nowrap;
        // overflow: hidden;
        // background-color: transparent;
        // background-position: center center;
        // background-repeat: no-repeat;
        // opacity: 0.7;
      }

      .pika-prev:hover,
      .pika-next:hover {
        opacity: 1;
      }

      .pika-prev {
        top: 50%;
        right: 2.5rem;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 19l-7-7 7-7'%3E%3C/path%3E%3C/svg%3E");
      }

      .pika-next {
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 5l7 7-7 7'%3E%3C/path%3E%3C/svg%3E");
      }

      .pika-prev.is-disabled,
      .pika-next.is-disabled {
        cursor: default;
        opacity: 0.2;
      }
    }

    table.pika-table {
      margin-bottom: 0;
      margin-top: 1rem;

      thead {
        background: unset;
        border: unset;

        tr th {
          color: $color-text;

          abbr {
            color: inherit;
            text-transform: uppercase;
            font-size: fonts.$size-xxs;
            font-weight: fonts.$weight-bold;
          }
        }
      }

      tbody {
        border: unset;

        .pika-row {
          background: colors.$white;

          td {
            &.is-today > button.pika-button {
              color: colors.$white !important;
            }

            &.is-today:not(.is-selected) > button.pika-button {
              color: colors.$purple !important;
            }

            &.is-selected > button.pika-button {
              box-shadow: unset;
              color: colors.$white;
              background: colors.$purple;
              border-radius: radii.$full;

              &:hover {
                color: colors.$purple !important;
              }
            }

            > button.pika-button {
              display: flex;
              align-items: center;
              justify-content: center;

              background: colors.$white;
              height: 2rem !important;
              width: 2rem !important;
              text-align: center;

              color: colors.$gray-900;
              font-size: fonts.$size-xs;

              &:focus,
              &:hover {
                color: colors.$purple;
                border-radius: radii.$full;
                background: colors.$green-10;
              }

              &.is-outside-current-month {
                color: $color-text-disabled;

                &:hover {
                  color: colors.$purple;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* stylelint-enable selector-max-class */
