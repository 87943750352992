@use '../../styles/design-system/shadows';
@use '../../styles/design-system/colors';
@use '../../styles/design-system/fonts';
@use '../../styles/design-system/radii';
@use 'sass:color';

.tlw-tooltip {
  z-index: 1000;
  position: absolute;
  min-width: 17.5rem;
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;

  background: colors.$white;
  border-radius: radii.$small;
  box-shadow: shadows.$small, shadows.$small, shadows.$extra-small;

  transition: opacity 150ms ease-in-out;

  h3 {
    text-align: left;
    color: colors.$purple;
    font-size: fonts.$size-xs;
    font-weight: fonts.$weight-semi-bold;
  }

  p {
    font-weight: fonts.$weight-regular;
    font-size: fonts.$size-sm;
    color: colors.$gray-900;
    margin-bottom: 0;

    text-align: left;
    user-select: text;
  }
}

.tlw-tooltip__link {
  color: colors.$purple;
  font-size: fonts.$size-sm;

  &:hover {
    color: color.adjust($color: colors.$electricGreen, $lightness: 10%);
  }
}
