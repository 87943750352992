@use '../../styles/design-system/colors';

/**
 * ------------------------------------
 *          Login / Signup
 * ------------------------------------
 */

@keyframes login-logo-appear-main {
  0% {
    opacity: 0;
    scale: 0.95;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes login-full-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
 * ------------------------------------
 *         Component-specific
 * ------------------------------------
 */
@keyframes full-screen-loader-delayed-rotate {
  40% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes full-screen-loader-slide {
  0% {
    transform: translateX(-1rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes loaders-overlay-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
